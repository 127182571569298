import React from 'react';
import { Link } from 'react-router-dom';

function Sidebar({ onLogout }) {
  return (
    <div className="bg-gray-800 text-white h-full w-1/4 flex flex-col items-start p-4">
      <h2 className="text-2xl font-bold mb-6">Админ Панель</h2>
      <Link to="products" className="mb-4 p-2 w-full text-left hover:bg-gray-700 rounded">
        Товары
      </Link>
      <Link to="quantity" className="mb-4 p-2 w-full text-left hover:bg-gray-700 rounded">
        Количество
      </Link>
      <Link to="orders" className="mb-4 p-2 w-full text-left hover:bg-gray-700 rounded">
        Заказы
      </Link>
      <Link to="characteristics" className="mb-4 p-2 w-full text-left hover:bg-gray-700 rounded">
        Характеристики
      </Link>
      <button
        onClick={onLogout}
        className="mt-6 p-2 w-full text-left bg-red-600 hover:bg-red-700 rounded">
        Выйти
      </button>
    </div>
  );
}

export default Sidebar;
