// src/components/CharacteristicsPanel.js
import React, { useEffect, useState } from 'react';
import {
  getCharacteristics,
  updateCharacteristic,
  addCharacteristic,
  deleteCharacteristic,
} from '../../api';

function Characteristics() {
  const [characteristics, setCharacteristics] = useState([]);
  const [newCharacteristic, setNewCharacteristic] = useState({ name: '', value: '', tooltip: '' });
  const [editingCharId, setEditingCharId] = useState(null);
  const [editedCharacteristic, setEditedCharacteristic] = useState({});

  useEffect(() => {
    const fetchCharacteristics = async () => {
      try {
        const data = await getCharacteristics();
        setCharacteristics(Array.isArray(data) ? data : []); // Убедитесь, что data — массив
      } catch (error) {
        console.error('Ошибка при загрузке товаров:', error);
        setCharacteristics([]); // Устанавливаем пустой массив при ошибке
      }
    };

    fetchCharacteristics();
  }, []);

  const handleAddCharacteristic = async () => {
    if (!newCharacteristic.name || !newCharacteristic.value) {
      alert('Заполните все поля для новой характеристики.');
      return;
    }
    try {
      const addedCharacteristic = await addCharacteristic(newCharacteristic);
      setCharacteristics([...characteristics, addedCharacteristic]);
      setNewCharacteristic({ name: '', value: '', tooltip: '' });
    } catch (error) {
      console.error('Ошибка при добавлении характеристики:', error);
    }
  };

  const handleDeleteCharacteristic = async (id) => {
    try {
      await deleteCharacteristic(id);
      setCharacteristics(characteristics.filter((char) => char.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении характеристики:', error);
    }
  };

  const handleEditCharacteristic = (char) => {
    setEditingCharId(char.id);
    setEditedCharacteristic(char);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedCharacteristic((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEdit = async () => {
    try {
      await updateCharacteristic(editingCharId, editedCharacteristic);
      setCharacteristics(
        characteristics.map((char) =>
          char.id === editingCharId ? { ...char, ...editedCharacteristic } : char,
        ),
      );
      setEditingCharId(null); // Закрываем режим редактирования
    } catch (error) {
      console.error('Ошибка при сохранении изменений характеристики:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingCharId(null);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Характеристики</h2>

      {/* Добавление новой характеристики */}
      <div className="mb-6 p-4 border rounded">
        <h3 className="font-bold mb-2">Добавить новую характеристику</h3>
        <input
          type="text"
          placeholder="Название"
          value={newCharacteristic.name}
          onChange={(e) => setNewCharacteristic({ ...newCharacteristic, name: e.target.value })}
          className="p-2 border mb-2 w-full"
        />
        <input
          type="text"
          placeholder="Значение"
          value={newCharacteristic.value}
          onChange={(e) => setNewCharacteristic({ ...newCharacteristic, value: e.target.value })}
          className="p-2 border mb-2 w-full"
        />
        <input
          type="text"
          placeholder="Текст для тултипа"
          value={newCharacteristic.tooltip}
          onChange={(e) => setNewCharacteristic({ ...newCharacteristic, tooltip: e.target.value })}
          className="p-2 border mb-2 w-full"
        />
        <button
          onClick={handleAddCharacteristic}
          className="bg-green-500 text-white px-4 py-2 rounded">
          Добавить
        </button>
      </div>

      {/* Список характеристик */}
      <ul>
        {characteristics.map((char) => (
          <li key={char.id} className="flex justify-between items-center mb-2 p-2 border rounded">
            {editingCharId === char.id ? (
              // Режим редактирования
              <div className="flex flex-grow items-center">
                <input
                  type="text"
                  name="name"
                  value={editedCharacteristic.name}
                  onChange={handleEditInputChange}
                  className="p-1 border mr-2"
                />
                <input
                  type="text"
                  name="value"
                  value={editedCharacteristic.value}
                  onChange={handleEditInputChange}
                  className="p-1 border mr-2"
                />
                <input
                  type="text"
                  name="tooltip"
                  value={editedCharacteristic.tooltip}
                  onChange={handleEditInputChange}
                  className="p-1 border mr-2"
                />
                <button
                  onClick={handleSaveEdit}
                  className="bg-green-500 text-white px-2 py-1 rounded mr-2">
                  Сохранить
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="bg-gray-400 text-white px-2 py-1 rounded">
                  Отмена
                </button>
              </div>
            ) : (
              // Режим отображения
              <div className="flex flex-grow items-center justify-between">
                <div>
                  <p className="font-bold">{char.name}</p>
                  <p>Значение: {char.value}</p>
                  <p className="text-sm text-gray-500">{char.tooltip}</p>
                </div>
                <div>
                  <button
                    onClick={() => handleEditCharacteristic(char)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded mr-2">
                    Редактировать
                  </button>
                  <button
                    onClick={() => handleDeleteCharacteristic(char.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded">
                    Удалить
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Characteristics;
