import React from 'react';

function Block1() {
  return (
    <div
      className="h-full flex items-center justify-center bg-cover bg-center pt-24 pb-4"
      style={{ backgroundImage: 'url("/images/block1.jpg")' }}>
      <img src="/images/block1_logo.png" alt="Логотип" className="w-1/2 md:w-1/4" />
    </div>
  );
}

export default Block1;
