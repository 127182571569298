// src/components/QuantityPanel.js
import React, { useEffect, useState } from 'react';
import { getQuantity, updateQuantity } from '../../api';

function Quantity() {
  const [quantity, setQuantity] = useState({ total_collected: 0, remaining: 0 });

  useEffect(() => {
    const fetchQuantity = async () => {
      try {
        const data = await getQuantity();
        console.log(data);
        setQuantity(data); // Устанавливаем полученный объект
      } catch (error) {
        console.error('Ошибка при загрузке товаров:', error);
        setQuantity({ total_collected: 0, remaining: 0 }); // Устанавливаем пустые значения при ошибке
      }
    };

    fetchQuantity();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setQuantity((prev) => ({ ...prev, [name]: Number(value) }));
  };

  const handleUpdateQuantity = async () => {
    try {
      await updateQuantity(quantity);
      alert('Количество обновлено успешно');
    } catch (error) {
      console.error('Ошибка при обновлении количества:', error);
    }
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Управление количеством</h2>
      <div className="mb-4">
        <label className="block mb-2">Всего собрали:</label>
        <input
          type="text"
          name="total_collected"
          value={quantity.total_collected}
          onChange={handleInputChange}
          className="p-2 border w-full"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">Осталось от урожая:</label>
        <input
          type="text"
          name="remaining"
          value={quantity.remaining}
          onChange={handleInputChange}
          className="p-2 border w-full"
        />
      </div>
      <button onClick={handleUpdateQuantity} className="bg-green-500 text-white px-4 py-2 rounded">
        Сохранить
      </button>
    </div>
  );
}

export default Quantity;
