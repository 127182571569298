import React, { useEffect, useState } from 'react';
import { getCharacteristics } from '../api';

function Block4() {
  const [characteristics, setCharacteristics] = useState([]);

  useEffect(() => {
    const fetchCharacteristics = async () => {
      try {
        const data = await getCharacteristics();
        if (Array.isArray(data)) {
          setCharacteristics(data);
        } else {
          console.error('Ошибка: данные не являются массивом', data);
          setCharacteristics([]);
        }
      } catch (error) {
        console.error('Ошибка при загрузке характеристик:', error);
        setCharacteristics([]);
      }
    };

    fetchCharacteristics();
  }, []);

  return (
    <div className="relative h-full w-full bg-black px-6 md:px-16 py-8 md:pt-32 text-white flex flex-col justify-around">
      <img
        src="/images/block3.jpg"
        alt="Background"
        className="absolute bottom-0 left-0 h-full object-cover"
      />

      <div className="flex flex-col gap-2 justify-around relative z-10">
        <h2 className="text-5xl md:text-4xl lg:text-5xl text-center lg:font-semibold font-bold">
          Уникальные характеристики
        </h2>
        <div className="flex justify-center pt-2 relative z-10 text-center">
          <h3 className="text-2xl">подтвержденные лабораторными испытаниями</h3>
        </div>
      </div>

      <div className="flex items-center justify-end relative z-10 py-8">
        <div className="flex flex-col gap-6 md:gap-8 text-2xl w-full md:w-3/4 lg:w-1/2 text-center lg:text-start">
          <div className="space-y-6 md:space-y-6">
            {characteristics.map((char) => (
              <div key={char.id} className="flex lg:flex-row flex-col items-center gap-3 md:gap-4">
                <img src="/images/bullits.png" className="w-8 h-8 md:w-8 md:h-8" />
                <div className="flex flex-col gap-2 md:flex-row relative items-center gap-0 md:gap-2 w-full md:w-auto">
                  <div className="bg-[#0f0d0d] px-4 lg:px-2 py-4 lg:py-1 rounded mb-1 relative cursor-pointer group w-full md:w-auto">
                    <span>{char.name}:</span>
                    <div className="z-50 absolute shadow-xl top-full mt-0 md:mt-2 left-1/2 transform -translate-x-1/2 w-full p-2 md:p-4 bg-white text-black text-sm md:text-xl rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
                      {char.tooltip}
                    </div>
                  </div>
                  <div className="lg:text-2xl text-3xl font-semibold">{char.value}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <a
        href="/protocol.pdf"
        target="_blank"
        rel="noopener noreferrer"
        className="flex z-[1] justify-end items-center">
        <img src="/images/frame_PDF.png" className="md:w-2/5 w-full" />
      </a>
    </div>
  );
}

export default Block4;
