// src/components/ProductsPanel.js
import React, { useEffect, useState } from 'react';
import { getProducts, addProduct, updateProduct, deleteProduct } from '../../api';

function Products() {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({ name: '', price: '', unit: '' });
  const [editingProductId, setEditingProductId] = useState(null);
  const [editedProduct, setEditedProduct] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts();
        setProducts(Array.isArray(data) ? data : []); // Убедитесь, что data — массив
      } catch (error) {
        console.error('Ошибка при загрузке товаров:', error);
        setProducts([]); // Устанавливаем пустой массив при ошибке
      }
    };

    fetchProducts();
  }, []);

  const toggleProductActive = async (id, is_active) => {
    try {
      const product = products.find((p) => p.id === id);

      if (!product) {
        console.error('Продукт не найден');
        return;
      }

      const updatedProduct = await updateProduct(id, {
        ...product,
        is_active: !is_active,
      });

      setProducts(
        products.map((p) => (p.id === id ? { ...p, is_active: updatedProduct.is_active } : p)),
      );
    } catch (error) {
      console.error('Ошибка при обновлении статуса товара:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddProduct = async () => {
    try {
      const addedProduct = await addProduct({ ...newProduct, is_active: true }); // Добавляем `isActive: true` для новых продуктов
      setProducts([...products, addedProduct]);
      setNewProduct({ name: '', price: '', unit: '' });
    } catch (error) {
      console.error('Ошибка при добавлении товара:', error);
    }
  };

  const handleDeleteProduct = async (id) => {
    try {
      await deleteProduct(id);
      setProducts(products.filter((product) => product.id !== id));
    } catch (error) {
      console.error('Ошибка при удалении товара:', error);
    }
  };

  const handleEditProduct = (product) => {
    setEditingProductId(product.id);
    setEditedProduct(product);
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;
    setEditedProduct((prev) => ({ ...prev, [name]: value }));
  };

  const handleSaveEdit = async () => {
    try {
      await updateProduct(editingProductId, editedProduct);
      setProducts(
        products.map((product) =>
          product.id === editingProductId ? { ...product, ...editedProduct } : product,
        ),
      );
      setEditingProductId(null); // Закрываем режим редактирования
    } catch (error) {
      console.error('Ошибка при сохранении изменений товара:', error);
    }
  };

  const handleCancelEdit = () => {
    setEditingProductId(null);
  };

  return (
    <div>
      <h2 className="text-2xl font-bold mb-4">Список товаров</h2>

      <div className="mb-6">
        <input
          type="text"
          name="name"
          placeholder="Название товара"
          value={newProduct.name}
          onChange={handleInputChange}
          className="p-2 border mb-2 w-full"
        />
        <input
          type="text"
          name="price"
          placeholder="Цена"
          value={newProduct.price}
          onChange={handleInputChange}
          className="p-2 border mb-2 w-full"
        />
        <input
          type="text"
          name="unit"
          placeholder="Единица измерения (шт/кг)"
          value={newProduct.unit}
          onChange={handleInputChange}
          className="p-2 border mb-2 w-full"
        />
        <button onClick={handleAddProduct} className="bg-blue-500 text-white px-4 py-2 rounded">
          Добавить товар
        </button>
      </div>

      <ul>
        {products.map((product) => (
          <li key={product.id} className="flex justify-between items-center mb-2 p-2 border">
            {editingProductId === product.id ? (
              // Режим редактирования
              <div className="flex flex-grow items-center">
                <input
                  type="text"
                  name="name"
                  value={editedProduct.name}
                  onChange={handleEditInputChange}
                  className="p-1 border mr-2"
                />
                <input
                  type="text"
                  name="price"
                  value={editedProduct.price}
                  onChange={handleEditInputChange}
                  className="p-1 border mr-2"
                />
                <input
                  type="text"
                  name="unit"
                  value={editedProduct.unit}
                  onChange={handleEditInputChange}
                  className="p-1 border mr-2"
                />
                <button
                  onClick={handleSaveEdit}
                  className="bg-green-500 text-white px-2 py-1 rounded mr-2">
                  Сохранить
                </button>
                <button
                  onClick={handleCancelEdit}
                  className="bg-gray-400 text-white px-2 py-1 rounded">
                  Отмена
                </button>
              </div>
            ) : (
              // Режим отображения
              <div className="flex flex-grow items-center justify-between">
                <div>
                  <p className="font-bold">{product.name}</p>
                  <p>
                    Цена: {product.price} р. / {product.unit}
                  </p>
                </div>
                <div>
                  <input
                    type="checkbox"
                    checked={product.is_active} // Галочка для переключения активности
                    onChange={() => toggleProductActive(product.id, product.is_active)}
                    className="mr-2"
                  />
                  <button
                    onClick={() => handleEditProduct(product)}
                    className="bg-yellow-500 text-white px-2 py-1 rounded mr-2">
                    Редактировать
                  </button>
                  <button
                    onClick={() => handleDeleteProduct(product.id)}
                    className="bg-red-500 text-white px-2 py-1 rounded">
                    Удалить
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Products;
