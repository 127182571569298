import React from 'react';

function Block2() {
  return (
    <div className="relative h-full w-full bg-black px-0 md:px-16 py-0 md:py-32 text-white flex flex-col justify-around">
      {/* Заголовок */}
      <div className="flex justify-center relative z-10 px-6 py-8 lg:py-0 lg:px-0">
        <h2 className="text-5xl md:text-4xl lg:text-5xl text-center lg:font-semibold font-bold">
          О заполярье
        </h2>
      </div>

      {/* Описание с иконками, центрированное по вертикали */}
      <div className="flex items-center relative z-10 px-6 py-8 lg:py-0 lg:px-0">
        <div className="flex flex-col gap-8 md:gap-10 lg:gap-12 text-2xl w-full md:w-1/2 pt-8 md:pt-12 lg:pt-16">
          <div className="space-y-4 md:space-y-8">
            <div className="flex flex-col text-center lg:text-start lg:flex-row lg:items-start items-center lg:justify-start justify-center gap-4">
              <img src="/images/bullits.png" className="w-8 md:w-8 h-8 md:h-8" />
              <p>
                Короткое северное лето обеспечивает невозможное в других регионах разнообразие
                одновременно цветущих медоносов
              </p>
            </div>
            <div className="flex flex-col text-center lg:text-start lg:flex-row lg:items-start items-center lg:justify-start justify-center gap-4">
              <img src="/images/bullits.png" className="w-8 md:w-8 h-8 md:h-8" />
              <p>
                Климатические условия Заполярья исключают возможность появления многих «пчелиных»
                болезней
              </p>
            </div>
            <div className="flex flex-col text-center lg:text-start lg:flex-row lg:items-start items-center lg:justify-start justify-center gap-4">
              <img src="/images/bullits.png" className="w-8 md:w-8 h-8 md:h-8" />
              <p>Чистая природа, незнакомая с химическими удобрениями и инсектицидами</p>
            </div>
          </div>
        </div>
      </div>
      {/* Фоновое изображение */}
      <img
        src="/images/map.png"
        alt="Background"
        className="relative lg:absolute bottom-0 lg:-bottom-24 right-0 h-full object-cover"
      />
    </div>
  );
}

export default Block2;
