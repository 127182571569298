// src/api.js
import axios from 'axios';

const API_URL = '/api';

export const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/login`, { username, password });
  return response.data;
};

export const getCharacteristics = async () => {
  const response = await axios.get(`${API_URL}/characteristics`);
  return response.data;
};

export const addCharacteristic = async (characteristic) => {
  const response = await axios.post(`${API_URL}/characteristics`, characteristic);
  return response.data;
};

export const updateCharacteristic = async (id, updatedCharacteristic) => {
  await axios.put(`${API_URL}/characteristics/${id}`, updatedCharacteristic);
};

export const deleteCharacteristic = async (id) => {
  await axios.delete(`${API_URL}/characteristics/${id}`);
};

// Запросы для products
export const getProducts = async () => {
  const response = await axios.get(`${API_URL}/products`);
  return response.data;
};

export const addProduct = async (product) => {
  const response = await axios.post(`${API_URL}/products`, product);
  return response.data;
};

export const updateProduct = async (id, product) => {
  const response = await axios.put(`${API_URL}/products/${id}`, product);
  return response.data; // Добавьте возврат данных
};

export const deleteProduct = async (id) => {
  await axios.delete(`${API_URL}/products/${id}`);
};

// Запросы для quantity
export const getQuantity = async () => {
  const response = await axios.get(`${API_URL}/quantity`);
  return response.data;
};

export const updateQuantity = async (quantity) => {
  await axios.put(`${API_URL}/quantity`, quantity); // убедитесь, что `quantity` отправляется корректно
};

// Запросы для orders
export const getOrders = async () => {
  const response = await axios.get(`${API_URL}/orders`);
  return response.data;
};

export const addOrder = async (order) => {
  const response = await axios.post(`${API_URL}/orders`, order);
  return response.data;
};

export const deleteOrder = async (id) => {
  await axios.delete(`${API_URL}/orders/${id}`);
};
