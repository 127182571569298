import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AdminPanel from './admin/AdminPanel'; // Импортируем компонент AdminPanel
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} /> {/* Главная страница */}
        <Route path="/admin/*" element={<AdminPanel />} />{' '}
        {/* Страница админ-панели с подмаршрутами */}
      </Routes>
    </Router>
  </React.StrictMode>,
);

reportWebVitals();
